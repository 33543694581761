import React, { Component } from 'react';
import styled from 'styled-components';
import Page from '../../components/page/Page';
import Button from '../../components/forms/Button';
import Input from '../../components/forms/Input';
import Header from '../../components/header/Header';
import Panel from '../../components/components/Panel';
import Loading from '../../components/components/Loading';
import PanelSection from '../../components/components/PanelSection';
import PanelTotals from '../../components/components/PanelTotals';
import Breadcrumbs from '../../components/components/Breadcrumbs';
import ErrorMessage from '../../components/components/ErrorMessage';
import Link from '../../components/forms/Link';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { formatPrice } from '../../utils/helpers';
import { updateOrder, updateEditOrder, loadOrder, loadOrderToEdit,loadOrderToDraft, clearDraftOrder } from '../../actions/orders';
import { fetchProducts, fetchCategories } from '../../actions/products';
import PropTypes from 'prop-types';
// import { history } from '../../store';
import { Modal } from 'react-bootstrap';
import Status from '../../components/components/Status';
import OrderStatusesDropdown from '../../components/orders/OrderStatusesDropdown';
import Shipping from '../../components/orders/Shipping';

import ProductList from '../../components/products/ProductList';
import ProductsSearch from '../../components/products/ProductsSearch';
import axios from 'axios';

const per_page = 20;
const StyledModal = styled(Modal)`
    .modal-body {
        padding: 0;
    }
    padding: 40px 0;
`;
const StyledForm = styled.div`
    .form-label {
        color: black;
        font-weight: bold;
        font-size: 0.875rem;
    }
    .form-control {
        border-radius: 0;
    }
    .date-picker-box {
        @media (min-width: 992px) {
            margin-left: -125px;
        }
    }
    .DayPickerInput {
        display: block;
    }
    .DayPicker-Day{
        width: 36px;
        height: 36px;
        padding: 0;
        min-width: 36px;
        box-sizing: border-box;
    }
    .DayPicker-Day--today {
        color: ${props => props.theme.blue };   
    }
    .DayPicker-Day--selected {
        background-color: ${props => props.theme.blue };
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
        background-color: ${props => props.theme.blue };
    }
      
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background-color: ${props => props.theme.blue };
    }
    .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: ${props => props.theme.yellow };
    }

    .address-update-contact {
        small {
            font-size: 13px;
        }
        a {
            text-decoration: underline;
            font-weight: bold;
        }
    }
`;
const StyledError = styled.div`
    padding: 15px;
    text-align: center;
    color: #dc3545;
    background-color: #fdccd1;
    border-radius: 4px;
    margin: 0 15px;
    margin-bottom: 15px;    
`;
const StyledTwoColumns = styled.div`
    @media (min-width: ${props => props.theme.tablet}) {
        display: flex;
        margin: 0 -10px;
    }
`;
const StyledHalfColumn = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        flex-grow: 1;
    }
    @media (min-width: ${props => props.theme.tablet}) {
        width: 50%;
        padding: 0 10px;    
    }
`;
function CustomOverlay({ classNames, selectedDay, children, ...props }) {
    return (
      <div
        className={`date-picker-box ${classNames.overlayWrapper}`}
        {...props}
      >
        <div className={classNames.overlay}>
          {children}
        </div>
      </div>
    );
}
export class EditOrder extends Component {
    cancelProductsTokenSignal = axios.CancelToken.source();
    cancelOrderTokenSignal = axios.CancelToken.source();
    cancelCategoriesTokenSignal = axios.CancelToken.source();
    isActive = false;
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadCategories = this.loadCategories.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleShowProducts = this.handleShowProducts.bind(this);
        this.handleCloseProducts = this.handleCloseProducts.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleShippingUpdate = this.handleShippingUpdate.bind(this);
        this.updateOrderTotals = this.updateOrderTotals.bind(this);
        
        this.id = props.match.params;
        this.state = {
            showProducts: false,
            submitted: false,
            isLoading: false,
            newStatus: null,
            isMainError: false,
            mainErrorMessage: '',
            errors: {
                date_required_by: null,
                contact_details: null,
                order_approver: null,
                cancelled_note: null,
                purchase_order_number: null,
                reason: null,
                products: null
            }
        };        
    }
    onChange = (e) => {
        e.preventDefault();
        const { name , value } = e.target;
        const edit = {
            ...this.props.edit,
            [name]: value
        }
        this.props.updateEditOrder(edit);
    }
    componentDidMount() {
        this.props.fetchProducts(1, per_page, this.cancelProductsTokenSignal);
        this.loadCategories(1, 20);
        const { id } = this.props.match.params;
        this.id = id;
        this.isActive = true;
        this.props.loadOrderToEdit(id);
        // this.props.loadOrderToDraft(id);
        const { status } = this.props.orders[id];
        this.setState({ newStatus: status.replace('wc-','') });
    }
    componentWillUnmount() {
        this.isActive = false;
        this.cancelProductsTokenSignal.cancel('Api Products is being canceled');
        this.cancelOrderTokenSignal.cancel('Api Order is being canceled');
        this.cancelCategoriesTokenSignal.cancel('Api Categories is being canceled');
    }
    loadCategories(page, per_page) {
        this.props.fetchCategories(page,per_page, this.cancelCategoriesTokenSignal).then(() => {
            const { total_categories_pages, categories_page } = this.props.products;
            if (categories_page < total_categories_pages) {
                this.loadCategories(categories_page + 1,per_page);
            }
        });
    }
    handleDayChange(selectedDay, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        const edit = {
            ...this.props.edit,
            date_required_by: input.value
        }
        this.props.updateEditOrder(edit);
    }
    handleSubmit(){
        this.setState({
            errors: {},
            isLoading: true
        });
        const { date_required_by, status, cancelled_note, contact_details, products, purchase_order_number} = this.props.edit;
        let error = false;
        if (this.state.newStatus === 'approved' && !purchase_order_number) {
            error = true;
        }
        if (this.state.newStatus === 'cancelled' && !cancelled_note) {
            error = true;
        }
        if (date_required_by && contact_details && products.length > 0 && !error) {
            try {
                this.props.updateOrder(this.props.orders[this.id], this.props.edit, this.props.auth.user.shipping, this.props.auth.user.id, this.state.newStatus).then(res => {
                    this.props.loadOrderToEdit(this.id);
                    this.setState({ 
                        submitted: true, 
                        isLoading: false
                    });
                }).catch(error => {
                    this.setState({ 
                        submitted: true, 
                        isLoading: false,
                        isMainError: true,
                        mainErrorMessage: `${error.name}: ${error.message}`
                    });
                });
            } catch(error) {
                this.setState({
                    submitted: true, 
                    isLoading: false,
                    isMainError: true,
                    mainErrorMessage: `${error.name}: ${error.message}`
                });
            }
        } else {
            const errors = {};
            if (this.state.newStatus === 'approved' && !purchase_order_number) {
                errors.purchase_order_number = "This is required";
            }
            if (!date_required_by) {
                errors.date_required_by = "This date is required";
            }
            if (!cancelled_note && status === 'cancelled') {
                errors.cancelled_note = "This note is required";
            }
            if (!contact_details) {
                errors.contact_details = "The contact details are required";
            }
            // if (!order_approver) {
            //     errors.order_approver = "The order approver is required";
            // }
            if (products.length === 0) {
                errors.products = "You need to add at least one product";
            }
            this.setState({
                errors: errors,
                isLoading: false
            })
        }        
    }
    handleCloseProducts() {
        this.setState({ showProducts: false });
    }
    handleShowProducts() {
        this.setState({ showProducts: true });       
    }
    updateProductInOrder = (product) => {
        this.setState({
            errors: {
                ...this.state.errors,
                products: null
            }
        });
        const products = [ ...this.props.edit.products ];
        const id = product.product_id ? product.product_id : product.id;
        const product_id = product.product_id ? product.product_id : product.id;
        const found = products.find(p => { 
            const the_id = p.product_id ? p.product_id : p.id;
            return the_id === id;
        });
        delete product.id;
        product.product_id = product_id;
        if (found) {
            const index = products.indexOf(found);
            product.id = products[index].id;
            products[index] = product;
        } else {
            products.push(product);
        }
        this.updateOrderTotals(products);
    }
    updateOrderTotals(products) {
        const total = products.reduce((sum, product) => {
            return sum + (parseFloat(product.price) * parseInt(product.quantity));
        },0);
        const shipping_lines =  this.props.edit.shipping_lines;
        let shipping = 0;
        if (shipping_lines.length> 0) {
            if (shipping_lines[0].total) {
                shipping = parseFloat(shipping_lines[0].total);
            }
        }

        this.props.updateEditOrder({
            ...this.props.edit,
            products,
            total: total + shipping
        });
    }
    deleteProductInOrder = (id) => {
        const products = [ ...this.props.edit.products ]
        const found = products.find(p => { 
            const the_id = p.product_id ? p.product_id : p.id;
            return the_id === id;
        });
        if (found) {
            const index = products.indexOf(found);
            products.splice(index,1);
        } 
        this.updateOrderTotals(products);
    }
    handleStatusChange(object, action) {
        if (action.action === 'select-option'){
            this.setState({newStatus: object.value.replace('wc-','')});
            // this.props.updateDraftOrderStatus(object.value.replace('wc-',''));
        } else {
            this.setState({ newStatus : this.props.edit.status});
            // this.props.updateDraftOrderStatus(null);
        }
    }
    handleShippingUpdate(shipping) {
        let edit = null;
        if (shipping) {
            const shipping_lines = this.props.edit.shipping_lines;
            if (shipping_lines.length > 0) {
                shipping_lines[0]['total'] = shipping.rate.toString();
            } else {
                shipping_lines.push({
                        method_id: "jem_table_rate",
                        method_title: "Table Rate",
                        total: shipping.rate.toString()
                    });
            }
            edit = {
                ...this.props.edit,
                shipping_lines
            };            
        } else {
            let total = 0;
            if (this.props.edit.products) {
                total = this.props.edit.products.reduce((sum, product) => {
                    return sum + (parseFloat(product.price) * parseInt(product.quantity));
                },0);
            }
            edit = {
                ...this.props.edit,
                shipping_lines: [],
                total
            };
        }
        this.props.updateEditOrder(edit);
        this.updateOrderTotals(edit.products);
    }
  render() {
    const { id } =this.props.match.params;
    const { billing, shipping, status, dealer_code, date_created, purchase_order_number, wbs_code, cost_centre, cancelled_note, date_required_by, contact_details, reason, total, products } = this.props.edit;
    let { newStatus } = this.state;
    if (newStatus === null) {
        newStatus = status.replace('wc-','');
    }
    
    const { hasNextPage, items } = this.props.products;
    const canEdit = (status === "approved" || status === 'cancelled') ? false : true;
    const inputProps = {
            className: "form-control",
            id: "date_required_by",
            autoComplete: "off"
    }
    if(this.state.errors.date_required_by){
        inputProps.className = "form-control is-invalid";
    }
    const { isMainError, mainErrorMessage} = this.state;
    const title = `Order #${id}`;
    const actions = <Button type="button" className="btn btn-primary" onClick={() => this.handleSubmit()}>Submit</Button>;
        return (
            <>
                <Header/>
                <Breadcrumbs>
                    <Link to="/">&lt; Back to orders</Link>                    
                </Breadcrumbs>
                <Page title={title} date={formatDate(date_created, 'dddd, MMMM Do YYYY, h:mm:ss a')} titleExtras={<Status type={status}>{status}</Status>} actions={actions}>
                    <StyledForm autoComplete="off">
                    <div className="order">
                        { isMainError && (<>
                            <ErrorMessage>Please contact the admin and provide the following message: <br/>{ mainErrorMessage }</ErrorMessage>
                            </>
                        )}
                        <div className="row align-items-start">
                            <div className="order-lg-last col-lg-3">
                                <Panel>
                                    { canEdit && 
                                    <div className="form-group">
                                        <h2>Change status</h2>
                                        <OrderStatusesDropdown onlyShow={ [ 'wc-awaiting-approval','wc-approved', 'wc-cancelled'] } handleStatusChange={this.handleStatusChange } selected={`wc-${newStatus}`}/>                                        
                                        { newStatus === 'cancelled' && <Input id="cancelled-note" type="textarea" rows="3" className="form-control" error={this.state.errors.cancelled_note} title="Cancelled notes" name="cancelled_note" placeholder="Note" onChange={(e) => this.onChange(e)} value={cancelled_note}/>}
                                    </div>}
                                    <div className="form-group">
                                        <Input id="purchase_order_number" error={this.state.errors.purchase_order_number} title="Purchase Order Number" type="text" className="form-control" name="purchase_order_number" placeholder="Order Number" onChange={(e) => this.onChange(e)} value={purchase_order_number}/> 
                                    </div>
                                    <div className="form-group">
                                        <Input id="wbs_code" error={this.state.errors.wbs_code} title="WBS Code" type="text" className="form-control" name="wbs_code" placeholder="" onChange={(e) => this.onChange(e)} value={wbs_code}/>                     
                                    </div>
                                    <div className="form-group">
                                        <Input id="cost_centre" error={this.state.errors.cost_centre} title="Cost Centre" type="text" className="form-control" name="cost_centre" placeholder="" onChange={(e) => this.onChange(e)} value={cost_centre}/>                     
                                    </div>
                                </Panel>    
                                <Panel>
                                    { dealer_code && (<>
                                        <div className="form-group"><label className="form-label">Dealer Code:</label><br/>{ dealer_code }</div>
                                        </>
                                    )}                                  
                                    <div className="form-group">
                                        <label htmlFor="date_required_by" className="form-label">Date required by</label>
                                        <DayPickerInput 
                                            overlayComponent={CustomOverlay}
                                            dayPickerProps={{
                                                disabledDays:{
                                                    before: new Date(),
                                                },
                                                tabIndex: 1                                
                                            }}
                                            inputProps={inputProps}
                                            onDayChange={this.handleDayChange}
                                            value={parseDate(date_required_by,'D/M/YYYY','au')}
                                            format="DD/MM/YYYY" placeholder="dd/mm/yyyy" formatDate={formatDate}
                            parseDate={parseDate} 
                                        />  
                                    </div>     
                                    <div className="form-group">
                                        <Input id="contact_details" error={this.state.errors.contact_details} title="Contact details" tooltip="Contact details of person placing the order" tooltipPlacement="top" type="text" className="form-control" name="contact_details" placeholder="Your name" onChange={(e) => this.onChange(e)} value={contact_details}/> 
                                    </div>
                                </Panel>
                            </div>
                            <div className="order-lg-first col-lg-9">
                                <StyledTwoColumns>
                                        <StyledHalfColumn>
                                            <Panel noPadding>
                                                <PanelSection title="Billing" noBorder>
                                                    { billing && (<>
                                                        { billing.company && ( <>{ billing.company }<br/></> )}{ billing.first_name } { billing.last_name }<br/>
                                                        { billing.address_1}{ billing.address_2 && (<> {billing.address_2}</>)}, {billing.city} {billing.state} {billing.postcode}
                                                    </>)}
                                                    <div className="address-update-contact"><small>Please <a href="mailto:info@vitag.com.au">contact us</a> if you require to update this info</small></div>
                                                </PanelSection>
                                            </Panel>
                                        </StyledHalfColumn>
                                        <StyledHalfColumn>
                                            <Panel noPadding>
                                                <PanelSection title="Shipping" noBorder>
                                                    { shipping && (<>
                                                        { shipping.company && ( <>{ shipping.company }<br/></> )}{ shipping.first_name } { shipping.last_name }<br/>
                                                        { shipping.address_1}{ shipping.address_2 && (<> {shipping.address_2}</>)}, {shipping.city} {shipping.state} {shipping.postcode}
                                                    </>)}
                                                    <div className="address-update-contact"><small>Please <a href="mailto:info@vitag.com.au">contact us</a> if you require to update this info</small></div>
                                                </PanelSection>
                                            </Panel>
                                        </StyledHalfColumn>
                                    </StyledTwoColumns>
                                <Panel paddingTop={ products.length > 0 ? true : undefined }>
                                    { this.state.errors.products && (<StyledError>You must add at least one product</StyledError>)} 
                                    <ProductList key="orderItems" isLoading={false} products={products} canEdit={canEdit} showQuantityInput={canEdit} showTotal showDeleteButton={canEdit} showQuantityInputOutOfStock updateProductInOrder={this.updateProductInOrder} deleteProductInOrder={this.deleteProductInOrder} handleShowProducts={this.handleShowProducts}/>                        
                                </Panel>
                                <Panel noPadding>
                                    <PanelSection>
                                        <Input id="reason" error={this.state.errors.reason} title="Reason for request" type="textarea" rows="3" className="form-control" name="reason" placeholder="" onChange={(e) => this.onChange(e)} value={reason}/> 
                                    </PanelSection>
                                </Panel>
                                <PanelTotals>
                                <Shipping products={ products } onShippingUpdate={this.handleShippingUpdate}/>
                                    <table> 
                                        <thead> 
                                            <tr>
                                                <th>Total: </th><td className="order__total">{formatPrice(total)}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"><small>Taxes are calculated at checkout</small></td>
                                            </tr>
                                        </thead>
                                    </table> 
                                </PanelTotals>
                            </div>
                        </div>
                        </div>
                    </StyledForm>
                    <StyledModal centered dialogClassName="modal-90w modal-products" show={this.state.showProducts} onHide={this.handleCloseProducts}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add products to the order</Modal.Title>                            
                        </Modal.Header>
                        <Modal.Body>
                            <ProductsSearch 
                                perPage={per_page}
                                hasNextPage={hasNextPage}
                                items={items}
                                updateProductInOrder={this.updateProductInOrder}                                
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-secondary" onClick={this.handleCloseProducts}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </StyledModal>
                    <Loading isLoading={this.state.isLoading}/>
                </Page>                    
            </>        
        )
  }
}

EditOrder.propTypes = {
    fetchProducts: PropTypes.func.isRequired, 
    fetchCategories: PropTypes.func.isRequired, 
    updateOrder: PropTypes.func.isRequired, 
    loadOrderToEdit: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    edit: PropTypes.object.isRequired,
    updateEditOrder: PropTypes.func.isRequired,
    orders: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired    
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        orders: state.orders.items,
        edit: state.orders.edit,
        products: state.products
    }
}
export default connect(mapStateToProps, { updateEditOrder, loadOrderToDraft, clearDraftOrder, updateOrder, loadOrder, loadOrderToEdit, fetchProducts, fetchCategories })(EditOrder)
