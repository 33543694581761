import React, { Component } from 'react';
import { formatPrice } from '../../utils/helpers';


class Shipping extends Component{
    constructor() {
        super();
        this._rates = [{
            from: 0,
            to: 5,
            rate: 20
        },{
            from: 6,
            to: null,
            rate: 20
        }];
        this.state = {
            shipping_rate: 0
        }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.products !== prevProps.products) {
            if (this.props.onShippingUpdate && typeof this.props.onShippingUpdate === 'function') {
                const shipping = this.calculate_shipping(this.props.products);
                this.props.onShippingUpdate(shipping);
            }            
        }
    }
    calculate_shipping(products) {

        const total_weight = products.reduce( (acum, product) => {
            return acum + (product.weight * product.quantity);
        },0);
        const total_products = products.reduce( (acum, product) => {
            return acum + (product.quantity);
        },0);
        if (total_products > 0) {
            const rates = [...this._rates];
            rates.reverse();
            
            const shipping = rates.find( rate => {
                return (rate.from <= total_weight && (rate.to >= total_weight || rate.to === null ));
            });
            return shipping;
        } else {
            return null;
        }
    }
    render() {
        const { products } = this.props;
        if (products) {
            const shipping = this.calculate_shipping(products);
            if (shipping) {
                return (<table> 
                    <thead> 
                        <tr>
                            <th>Shipping: </th><td className="order__total">{formatPrice(shipping.rate)}</td>
                        </tr>
                    </thead>
                </table>);
            }
            return '';
        } else {
            return '';
        }
    }
}

export default Shipping;