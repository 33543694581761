import React, { Component } from 'react';
import styled from 'styled-components';
import Page from '../../components/page/Page';
import Button from '../../components/forms/Button';
import Input from '../../components/forms/Input';
import Header from '../../components/header/Header';
import Panel from '../../components/components/Panel';
import Loading from '../../components/components/Loading';
import ErrorMessage from '../../components/components/ErrorMessage';
import PanelSection from '../../components/components/PanelSection';
import PanelTotals from '../../components/components/PanelTotals';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { formatPrice } from '../../utils/helpers';
import { submitOrder, clearDraftOrder, updateDraftOrder } from '../../actions/orders';
import { fetchProducts, fetchCategories } from '../../actions/products';
import PropTypes from 'prop-types';
import { history } from '../../store';
import { Modal } from 'react-bootstrap';
import Shipping from '../../components/orders/Shipping';

import ProductList from '../../components/products/ProductList';
import ProductsSearch from '../../components/products/ProductsSearch';

const per_page = 20;
const StyledModal = styled(Modal)`
    .modal-body {
        padding: 0;
    }
    padding: 40px 0;
`;
const StyledForm = styled.div`
    .form-label {
        color: black;
        font-weight: bold;
        font-size: 0.875rem;
    }
    .form-control {
        border-radius: 0;
    }
    .date-picker-box {
        @media (min-width: 992px) {
            margin-left: -125px;
        }
    }
    .DayPickerInput {
        display: block;
    }
    .DayPicker-Day{
        width: 36px;
        height: 36px;
        padding: 0;
        min-width: 36px;
        box-sizing: border-box;
    }
    .DayPicker-Day--today {
        color: ${props => props.theme.blue };   
    }
    .DayPicker-Day--selected {
        background-color: ${props => props.theme.blue };
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
        background-color: ${props => props.theme.blue };
    }
      
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background-color: ${props => props.theme.blue };
    }
    .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: ${props => props.theme.yellow };
    }
    .address-update-contact {
        small {
            font-size: 13px;
        }
        a {
            text-decoration: underline;
            font-weight: bold;
        }
    }
`;
const StyledError = styled.div`
    padding: 15px;
    text-align: center;
    color: #dc3545;
    background-color: #fdccd1;
    border-radius: 4px;
    margin: 0 15px;
    margin-bottom: 15px;    
`;
const StyledTwoColumns = styled.div`
    @media (min-width: ${props => props.theme.tablet}) {
        display: flex;
        margin: 0 -10px;
    }
`;
const StyledHalfColumn = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        flex-grow: 1;
    }
    @media (min-width: ${props => props.theme.tablet}) {
        width: 50%;
        padding: 0 10px;    
    }
`;
function CustomOverlay({ classNames, selectedDay, children, ...props }) {
    return (
      <div
        className={`date-picker-box ${classNames.overlayWrapper}`}
        {...props}
      >
        <div className={classNames.overlay}>
          {children}
        </div>
      </div>
    );
}
const initialState = {
    showProducts: false,
    submitted: false,
    isLoading: false,
    isMainError: false,
    mainErrorMessage: '',
    errors: {
        date_required_by: null,
        contact_details: null,
        order_approver: null,
        reason: null,
        customer_note: null,
        products: null
    }
}
export class NewOrderPage extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadCategories = this.loadCategories.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleShowProducts = this.handleShowProducts.bind(this);
        this.handleCloseProducts = this.handleCloseProducts.bind(this);
        this.handleShippingUpdate = this.handleShippingUpdate.bind(this);
        this.updateOrderTotals = this.updateOrderTotals.bind(this);
        this.handleChangeStore = this.handleChangeStore.bind(this);
        this.state = {
            showProducts: false,
            submitted: false,
            isLoading: false,
            errors: {
                date_required_by: null,
                contact_details: null,
                order_approver: null,
                customer_note: null,
                reason: null,
                products: null
            }
        };        
    }
    onChange = (e) => {
        e.preventDefault();
        const { name , value } = e.target;
        const draft_order = {
            ...this.props.draft_order,
            [name]: value
        }
        this.props.updateDraftOrder(draft_order);
    }
    componentDidMount() {
        this.props.fetchProducts(1, per_page);
        this.loadCategories(1, 20);
        let status = 'pending';
        if (this.props.auth.user.roles[0] === 'company_owned_store'){
            status = 'awaiting-approval';
        }
        const dealer_code = this.props.auth.current_store;
        const current_details = this.props.auth.user.stores.find( store => {
            return store.dealer_code === dealer_code;
        })
        const { shipping, billing } = current_details;
        const draft_order = {
            ...this.props.draft_order,
            bp_code: this.props.auth.user.bp_code,
            dealer_code,
            shipping,
            billing,
            status: status
        }
        this.props.updateDraftOrder(draft_order);
    }
    loadCategories(page, per_page) {
        this.props.fetchCategories(page,per_page).then(() => {
            const { total_categories_pages, categories_page } = this.props.products;
            if (categories_page < total_categories_pages) {
                this.loadCategories(categories_page + 1,per_page);
            }
        });
    }
    handleDayChange(selectedDay, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        const draft_order = {
            ...this.props.draft_order,
            date_required_by: input.value
        }
        this.props.updateDraftOrder(draft_order);
    }
    handleChangeStore() {
        const dealer_code = this.props.auth.current_store;
        const current_details = this.props.auth.user.stores.find( store => {
            return store.dealer_code === dealer_code;
        })
        const { shipping, billing } = current_details;
        const draft_order = {
            ...this.props.draft_order,
            dealer_code,
            shipping,
            billing
        }
        this.props.updateDraftOrder(draft_order);
    }
    handleSubmit(){
        this.setState({
            isLoading: true
        });
        const { date_required_by, contact_details, products} = this.props.draft_order;
        if (date_required_by && contact_details && products.length > 0) {
            try {
                this.props.submitOrder(this.props.draft_order, this.props.auth.user.id).then(res => {
                    this.setState({ 
                        submitted: true, 
                        isLoading: false
                    });
                    this.props.clearDraftOrder();
                    this.setState(initialState);
                    const order = res.data;
                    if( order.id ){
                        history.push(`/orders/view/${order.id}`);
                    }
                }).catch(error => {
                    this.setState({ 
                        submitted: true, 
                        isLoading: false,
                        isMainError: true,
                        mainErrorMessage: `${error.name}: ${error.message}`
                    });
                });
            } catch(error) {
                this.setState({
                    submitted: true, 
                    isLoading: false,
                    isMainError: true,
                    mainErrorMessage: `${error.name}: ${error.message}`
                });
            }
        } else {
            const errors = {};
            if (!date_required_by) {
                errors.date_required_by = "This date is required";
            }
            if (!contact_details) {
                errors.contact_details = "The contact details are required";
            }
            if (products.length === 0) {
                errors.products = "You need to add at least one product";
            }
            this.setState({
                errors: errors,
                isLoading: false
            })
        }        
    }
    handleCloseProducts() {
        this.setState({ showProducts: false });
    }
    handleShowProducts() {
        this.setState({ showProducts: true });       
    }
    updateOrderTotals(updatedProducts) {
        const total = updatedProducts.reduce((sum, product) => {
            return sum + (parseFloat(product.price) * parseInt(product.quantity));
        },0);
        const shipping_lines =  this.props.draft_order.shipping_lines;
        let shipping = 0;
        if (shipping_lines.length> 0) {
            if (shipping_lines[0].total) {
                shipping = parseFloat(shipping_lines[0].total);
            }
        }
        const products = updatedProducts ? updatedProducts : [...this.props.draft_order.products];
        this.props.updateDraftOrder({
            ...this.props.draft_order,
            products,
            total: total + shipping
        });
    }
    updateProductInOrder = (product) => {
        this.setState({
            errors: {
                ...this.state.errors,
                products: null
            }
        });
        const products = [ ...this.props.draft_order.products ];
        const id = product.product_id ? product.product_id : product.id;
        const product_id = product.product_id ? product.product_id : product.id;
        const found = products.find(p => { 
            const the_id = p.product_id ? p.product_id : p.id;
            return the_id === id;
        });
        delete product.id;
        product.product_id = product_id;
        if (found) {
            const index = products.indexOf(found);
            product.id = products[index].id;
            products[index] = product;      
        } else {
            products.push(product);
        }
        this.updateOrderTotals(products);
    }
    deleteProductInOrder = (id) => {
        const products = [ ...this.props.draft_order.products ]
        const found = products.find(p => { 
            const the_id = p.product_id ? p.product_id : p.id;
            return the_id === id;
        });
        if (found) {
            const index = products.indexOf(found);
            products.splice(index,1);
        }
        this.updateOrderTotals(products);
    }
    handleShippingUpdate(shipping) {
        let draft_order = {...this.props.draft_order};
        
        if (shipping) {
            const shipping_lines = this.props.draft_order.shipping_lines;
            if (shipping_lines.length > 0) {
                shipping_lines['total'] = shipping.rate.toString();
            } else {
                shipping_lines.push({
                        method_id: "jem_table_rate",
                        method_title: "Table Rate",
                        total: shipping.rate.toString()
                    });
            }
            draft_order = {
                ...this.props.draft_order,
                shipping_lines
            };
            
        } else {
            const total = this.props.draft_order.products.reduce((sum, product) => {
                return sum + (parseFloat(product.price) * parseInt(product.quantity));
            },0);
            draft_order = {
                ...this.props.draft_order,
                shipping_lines: [],
                total
            };
        }
        this.props.updateDraftOrder(draft_order);
        this.updateOrderTotals(draft_order.products);
    }
    render() {
        const { date_required_by, contact_details, reason, customer_note, products, total} = this.props.draft_order;
        const { hasNextPage, items } = this.props.products;
        const { roles } = this.props.auth.user;

        const dealer_code = this.props.auth.current_store;
        const current_details = this.props.auth.user.stores.find( store => {
            return store.dealer_code === dealer_code;
        })
        const { shipping, billing } = current_details;
        let is_company_owned_store = false;
        if (roles) {
            is_company_owned_store = (roles[0] === 'company_owned_store');
        }
        const inputProps = {
                className: "form-control",
                id: "date_required_by",
                autoComplete: "off"
        }
        if(this.state.errors.date_required_by){
            inputProps.className = "form-control is-invalid";
        }
        const { isMainError, mainErrorMessage} = this.state;
        
        const actions = <Button type="button" className="btn btn-primary" onClick={() => this.handleSubmit()}>Submit</Button>;
        return (
            <>
                <Header/>
                <Page title="New Order" actions={actions} handleChange={ this.handleStoreChange }>
                    <StyledForm autoComplete="off">
                    <div className="order">
                        { isMainError && (<>
                            <ErrorMessage>Please contact the admin and provide the following message: <br/>{ mainErrorMessage }</ErrorMessage>
                            </>
                        )}
                        <div className="row align-items-start">
                            <div className="order-lg-last col-lg-3">
                                <Panel>
                                    { dealer_code && (<>
                                        <div className="form-group"><label className="form-label">Dealer Code:</label><br/>{ dealer_code }</div>
                                        </>
                                    )}
                                    <div className="form-group">
                                        <label htmlFor="date_required_by" className="form-label">Date required by</label>
                                        <DayPickerInput 
                                            overlayComponent={CustomOverlay}
                                            dayPickerProps={{
                                                disabledDays:{
                                                    before: new Date(),
                                                },
                                                tabIndex: 1                                
                                            }}
                                            inputProps={inputProps}
                                            onDayChange={this.handleDayChange}
                                            value={date_required_by}
                                            format="DD/MM/YYYY" placeholder="dd/mm/yyyy" formatDate={formatDate}
                            parseDate={parseDate} 
                                        />  
                                    </div>     
                                    <div className="form-group">
                                        <Input id="contact_details" error={this.state.errors.contact_details} title="Contact details" tooltip="Contact details of person placing the order" tooltipPlacement="top" type="text" className="form-control" name="contact_details" placeholder="Your name" onChange={(e) => this.onChange(e)} value={contact_details}/> 
                                    </div>
                                </Panel>
                            </div>
                            <div className="order-lg-first col-lg-9">
                                <StyledTwoColumns>
                                    <StyledHalfColumn>
                                        <Panel noPadding>
                                            <PanelSection title="Billing" noBorder>
                                                { billing && (<>
                                                    { billing.company && ( <>{ billing.company }<br/></> )}{ billing.first_name } { billing.last_name }<br/>
                                                    { billing.address_1}{ billing.address_2 && (<> {billing.address_2}</>)}, {billing.city} {billing.state} {billing.postcode}
                                                </>)}
                                                <div className="address-update-contact"><small>Please <a href="mailto:info@vitag.com.au">contact us</a> if you require to update this info</small></div>
                                            </PanelSection>
                                        </Panel>
                                    </StyledHalfColumn>
                                    <StyledHalfColumn>
                                        <Panel noPadding>
                                            <PanelSection title="Shipping" noBorder>
                                                { shipping && (<>
                                                    { shipping.company && ( <>{ shipping.company }<br/></> )}{ shipping.first_name } { shipping.last_name }<br/>
                                                    { shipping.address_1}{ shipping.address_2 && (<> {shipping.address_2}</>)}, {shipping.city} {shipping.state} {shipping.postcode}
                                                </>)}
                                                <div className="address-update-contact"><small>Please <a href="mailto:info@vitag.com.au">contact us</a> if you require to update this info</small></div>
                                            </PanelSection>
                                        </Panel>
                                    </StyledHalfColumn>
                                </StyledTwoColumns>
                                <Panel paddingTop={ products.length > 0 ? true : undefined }>
                                    { this.state.errors.products && (<StyledError>You must add at least one product</StyledError>)} 
                                    <ProductList isLoading={false} products={products} showQuantityInput showTotal showDeleteButton showQuantityInputOutOfStock updateProductInOrder={this.updateProductInOrder} deleteProductInOrder={this.deleteProductInOrder} handleShowProducts={this.handleShowProducts}/>                        
                                </Panel>
                                {is_company_owned_store && (<Panel noPadding>
                                    <PanelSection>
                                        <Input id="reason" error={this.state.errors.reason} title="Reason for request" type="textarea" rows="2" className="form-control" name="reason" placeholder="" onChange={(e) => this.onChange(e)} value={reason}/> 
                                    </PanelSection>
                                </Panel>)}
                                <Panel noPadding>
                                    <PanelSection>
                                        <Input id="customer_note" error={this.state.errors.customer_note} title="Note" type="textarea" rows="3" className="form-control" name="customer_note" placeholder="" onChange={(e) => this.onChange(e)} value={customer_note}/> 
                                    </PanelSection>
                                </Panel>
                                <PanelTotals>
                                    <Shipping products={products} onShippingUpdate={this.handleShippingUpdate } />
                                    <table> 
                                        <thead> 
                                            <tr>
                                                <th>Total: </th><td className="order__total">{formatPrice(total)}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"><small>Taxes are calculated at checkout</small></td>
                                            </tr>
                                        </thead>
                                    </table> 
                                </PanelTotals>
                            </div>
                        </div>
                        </div>
                    </StyledForm>
                    <StyledModal centered dialogClassName="modal-90w modal-products" show={this.state.showProducts} onHide={this.handleCloseProducts}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add products to the order</Modal.Title>                            
                        </Modal.Header>
                        <Modal.Body>
                            <ProductsSearch 
                                perPage={per_page}
                                hasNextPage={hasNextPage}
                                items={items}
                                updateProductInOrder={this.updateProductInOrder}                                
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-secondary" onClick={this.handleCloseProducts}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </StyledModal>
                    <Loading isLoading={this.state.isLoading}/>
                </Page>                    
            </>        
        )
    }
}

NewOrderPage.propTypes = {
    updateDraftOrder: PropTypes.func.isRequired, 
    fetchProducts: PropTypes.func.isRequired, 
    fetchCategories: PropTypes.func.isRequired, 
    submitOrder: PropTypes.func.isRequired, 
    auth: PropTypes.object.isRequired,
    draft_order: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired    
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        draft_order: state.orders.draft,
        products: state.products
    }
}
export default connect(mapStateToProps, { clearDraftOrder, submitOrder, fetchProducts, fetchCategories, updateDraftOrder })(NewOrderPage)
