import React, { Component } from 'react';
import Page from '../../components/page/Page';
import Header from '../../components/header/Header';
import Panel from '../../components/components/Panel';
import OrdersList from '../../components/orders/OrdersList';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from '../../components/forms/Link';
import { fetchOrders, createDraftFromOrder } from '../../actions/orders';
import { history } from '../../store';
import OrderStatusesDropdown from '../../components/orders/OrderStatusesDropdown';
import Pagination from '../../components/page/Pagination';
import Loading from '../../components/components/Loading';
import axios from 'axios';

const per_page = 1000;

export class ListOrders extends Component {
    //This is to cancell the fetch requests when unmount
    
    constructor(props){
        super(props);
        this.cancelTokenSignal = null;
        this.cancelTokenPageChangeSignal = null;
        this.loadingOrders = false;
        this.handleReorder = this.handleReorder.bind(this);
        this.handleOrderClick = this.handleOrderClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        
        this.state = {
            search: '',
            searchByStatus: '',
            searchResults:[],
            isSearched: false,
            loadingOrders: true
        };
    }
    componentDidMount() {
        this.cancelTokenSignal = axios.CancelToken.source();
        this.cancelTokenPageChangeSignal = axios.CancelToken.source();
        const stores = this.props.auth.user.stores;
        
        if (stores && stores.length === 1) {
            this.setState({
                dealer_code: stores[0].dealer_code
            });
        }
        this.props.fetchOrders(this.cancelTokenSignal, this.props.orders.page, per_page).then( res => {
            if (res !== 'cancelled'){
                this.setState({ 
                    loadingOrders: false
                });
            }
        });        
    }
    componentWillUnmount() {
        this.cancelTokenSignal.cancel('Api is being canceled');
        this.cancelTokenPageChangeSignal.cancel('Api is being canceled');
    }
    handleReorder(event, order_id) {
        event.stopPropagation();
        this.props.createDraftFromOrder(order_id);
        history.push('orders/new');
    }
    handleOrderClick(key) {
        if (this.props.auth.user) {
            if ((this.props.auth.user.roles[0] === 'profit_protection')) {
                history.push(`/orders/edit/${key}`);
            } else {
                history.push(`/orders/view/${key}`);        
            }
        }
    }
    handlePageChange(data) {
        this.props.fetchOrders(this.cancelTokenPageChangeSignal, data.currentPage,per_page);
    }
    handleStatusChange(object, action) {
        if (action.action === 'select-option'){
            this.setState({
                searchByStatus: object.value,
                isSearched: true
            });
        } else {
            let isSearched = true;
            if (this.state.search === ''){
                isSearched = false;
            }
            this.setState({
                searchByStatus: '',
                isSearched
            });
        }
    }
    handleSearch(e) {
        const value = e.target.value;
        let isSearched = true;
        if (this.state.searchByStatus === '' && value === ''){
            isSearched = false;
        }
        this.setState({
            search: value,
            isSearched
        });
    }
    render() { 
        const { orders, auth } = this.props;
        const { items, total_items, per_page, page, is_loading } = orders;
        
        if (auth.user.stores && auth.user.stores.length > 1) {

        }
        let filteredOrders = items;
        if (Object.keys(items).length > 0){
            filteredOrders = Object.keys(items).filter(key => {
                let found = true;
                if (this.state.search !== ''){
                    if (items[key].id.toString().indexOf(this.state.search) !== -1 || items[key].status.indexOf(this.state.search) !== -1 || items[key].invoice_number.indexOf(this.state.search) !== -1 || items[key].purchase_order_number.indexOf(this.state.search) !== -1 || items[key].reason.indexOf(this.state.search) !== -1){
                        found = true
                    } else {
                        found = false;
                    }
                }
                if (this.state.searchByStatus !== '' && found === true){
                    if (items[key].status.indexOf(this.state.searchByStatus.replace('wc-','')) !== -1) {
                        found = true;
                    } else {
                        found = false;
                    }
                }
                return found;
            }).map( key => {
                return items[key];
            });
            filteredOrders.sort(function(a,b){
                return new Date(b.date_created) - new Date(a.date_created);
            });
        }
        let is_profit_protection = false;
        let is_company_owned_store = false;
        if (auth.user) {
            is_profit_protection = (auth.user.roles[0] === 'profit_protection');
            is_company_owned_store = (auth.user.roles[0] === 'company_owned_store');
        }
        let show_reorder = false;
        if (items && is_profit_protection){
            show_reorder = false;
        }
        const title = (is_profit_protection) ? "All orders" : "Your orders";
        const actions = (is_profit_protection) ? null : (<Link to="/orders/new" className="btn btn-primary btn-block-xs">New order</Link>);
        return (
            <>
                <Header/>
                <Page title={title} actions={actions}>
                    { ( this.state.dealer_code !== null || is_profit_protection) && 
                    <><div className="row">
                        <div className="col-sm-12">
                            <Panel>
                            { filteredOrders && <div className="row"><div className="col"><input className="form-control" placeholder="Start typing order or invoice number" onChange={(e)=>this.handleSearch(e)} name="search"/></div><div className="col"><OrderStatusesDropdown handleStatusChange={this.handleStatusChange } selected={this.state.searchByStatus}/></div></div>}
                            { filteredOrders && <OrdersList key={`orders-list`} showCustomerDetails={is_profit_protection} isSearchResult={this.state.isSearched} isLoading={this.state.loadingOrders } showPurchaseOrderNumber={(is_profit_protection || is_company_owned_store) ? true : false} orders={filteredOrders} buttonAction={this.handleReorder} handleClick={this.handleOrderClick} showReorder={show_reorder} buttonName="Re-order"/>}
                            { total_items ? <Pagination currentPage={page > 0 ? page : 1} totalRecords={total_items} pageLimit={per_page} pageNeighbours={2} onPageChanged={data => this.handlePageChange(data)} /> : ''}
                            </Panel>
                        </div>                        
                    </div>
                    <Loading isLoading={is_loading}/></>
                    }
                </Page>   
            </>    
        )
  }
}
ListOrders.propTypes = {
    fetchOrders: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    orders: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        orders: state.orders
    }
}
export default connect(mapStateToProps, { fetchOrders, createDraftFromOrder })(ListOrders)