import React, { Component } from 'react';
import styled from 'styled-components';
import Page from '../components/page/Page';
import Header from '../components/header/Header';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Panel from '../components/components/Panel';
import PanelSection from '../components/components/PanelSection';

const StylePage = styled.div`
    background: ${props => props.theme.blue };   
    min-height: 100vh;    
`;

export class ProfilePage extends Component {
    
    render() { 
        const { user } = this.props.auth;
        const { shipping } = user;
        return (
            <StylePage>
                <Header/>
                <Page title="Profile">
                    <Panel noPadding>
                        <PanelSection title="Name">
                            <div>{user.name}</div>
                        </PanelSection>
                        <PanelSection title="Email">
                            <div>{user.email}</div>
                        </PanelSection>
                        <PanelSection title="Store Address">
                            <div>{ shipping.address_1}{ shipping.address_2 && (<> {shipping.address_2}</>)} {shipping.city} {shipping.state} {shipping.postcode}</div>
                        </PanelSection>
                        <PanelSection noBorder>
                            <div>
                                If any of your details needs to be updated, please <a href="mailto:info@vitag.com.au">contact us</a>
                            </div>
                        </PanelSection>                  
                    </Panel>
                </Page>
            </StylePage>        
        )
  }
}
ProfilePage.propTypes = {
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
    }
}
export default connect(mapStateToProps, { })(ProfilePage)